//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            page: 1,
            addressList: [],
            dialogVisible: false,
            form: {
                contact: '',
                contact_number: '',
                address: '',
                house_number: '',
                is_default: true,
                province: '',
                city: '',
                district: '',
                longitude: '',
                latitude: '',
                address_id: '',
           
            },
            formCopy: '',
            addressId: '',
            locale: window.localStorage.getItem('locale')|| this.$i18n.locale,
        }
    },
    created() {
        this.getAddressList()
        this.formCopy = Object.assign({}, this.form)
    },
    methods: {
        handleClose(val){
            this.form = Object.assign({}, this.formCopy)
            this.dialogVisible = false
        },

        edit(form) {
            form.is_default = Boolean(form.is_default)
            this.form = form
            this.dialogVisible = true
        },
        

        //获取地址列表
        async getAddressList() {
            const res = await this.$http.get('/user.address', {
                page: this.page,
                size: 1000
            });

            if (res.code !== 0) {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }

            this.addressList = res.data.list;
        },

        async formSubmit() {
            let form = this.form;
            // ... (rest of the form preparation code)

            const api = '/user.address/' + (form.address_id ? 'edit' : 'add');
            const res = await this.$http.post(api, form);

            if (res.code == 0) {
                this.dialogVisible = false;
                this.addressId = '';
                this.getAddressList();
            } else {
                this.$store.dispatch('global/handleMessage', { message: res.message });
            }
        },

        async deleteAddress(address) {
            const isEnglish = this.locale.toUpperCase() === 'EN';
            const confirmText = isEnglish 
                ? 'This operation will permanently delete the address. Continue?'
                : '此操作将永久删除, 是否继续?';
            const confirmOptions = {
                confirmButtonText: isEnglish ? 'Confirm' : '確定',
                cancelButtonText: isEnglish ? 'Cancel' : '取消',
                type: 'warning'
            };

            try {
                await this.$confirm(confirmText, isEnglish ? 'Warning' : '提示', confirmOptions);
                await this.deleteAddressItem(address);
            } catch {
                this.$message({
                    type: 'info',
                    message: isEnglish ? 'Deletion cancelled' : '已取消删除'
                });
            }
        },

        async deleteAddressItem(address) {
            const res = await this.$http.post('/user.address/delete', {
                address_id: address.address_id
            });

            if (res.data.code !== 0) {
                const successMessage = this.locale.toUpperCase() === 'EN' ? 'Successfully deleted!' : '删除成功!';
                this.$message({type: 'success', message: successMessage});
            } else {
                return this.handleErrorMessage(res.message);
            }

            this.getAddressList();
        }
    }
}
